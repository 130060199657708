/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3",
    ol: "ol"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "benign-paroxysmal-positional-vertigo-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#benign-paroxysmal-positional-vertigo-bppv",
    "aria-label": "benign paroxysmal positional vertigo bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Benign paroxysmal positional vertigo (BPPV)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/presbycusis-hero.png",
    alt: "Leaf falling from the sky",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-bppv",
    "aria-label": "what is bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is BPPV?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Benign paroxysmal positional vertigo (BPPV) is a feeling of vertigo which occurs due to a change in position. The word “benign” means harmless, while “paroxysmal” means spasmodic. In some cases, the designation “peripheral paroxysmal positional vertigo” (PPPV) is used, meaning the same kind of dizziness (vertigo in Latin and English). In this connection, peripheral means involving an outer area of the body."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In principle, BPPV is harmless, but it is nevertheless unpleasant for those who experience it daily. Doctors believe that a detachment of the otoliths (also known as ear stones or statoliths) from the macular organs (macula – small area in the vestibule), the utricle and the saccule."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These ear stones then find their way into the vestibules of the balance organs, causing the dizziness. You will find more information about the causes, symptoms, and treatment of BPPV in the following sections below."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-symptoms-of-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-symptoms-of-bppv",
    "aria-label": "the symptoms of bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The symptoms of BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are different symptoms which are characteristic of BPPV:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Turning over in bed"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sitting up from a position lying down"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Bending over quickly"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Turning onto the affected side"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It can also involve a so-called rotary vertigo, which usually occurs when the head changes position very quickly. This rotary dizziness is often referred to as a carousel in the head (carousel dizziness). It can occur in the following situations:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Occasional occurrence (paroxysmal)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Occurrence depends on position"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Duration of a few seconds"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ends on its own"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If the positional vertigo occurs while lying down and turning from one side to the other, cardiovascular causes can be excluded as a rule. Other forms of dizziness occur most frequently due to disorders of the inner ear, the eyes, or the brain."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Vertigo-semicircular-canals.jpg",
    alt: "Vertigo Semicircular canals",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "frequency-age-and-gender-of-people-affected-with-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#frequency-age-and-gender-of-people-affected-with-bppv",
    "aria-label": "frequency age and gender of people affected with bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Frequency, age, and gender of people affected with BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Approximately 2 out of 100 people experience BPPV. It appears most commonly in the elderly, typically in their fifties, and during the later years between 60 and 80. Considerably more ", React.createElement(_components.strong, null, "women are affected than men"), ". It recurs in 30% to 50% of affected individuals regardless of treatment. An ENT physician can prescribe special home exercises for a patient who wants try self-treatment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The diagnosis of vertigo, or BPPV, is usually occurs often with ", React.createElement(_components.strong, null, "people who are older"), ". This is because the ear stones loosen and detach as part of the aging process and consequently shift into a canal. In addition to advancing age, any succession of accidents can also be a factor leading to vertigo at an advanced age. An example would be a head injury."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To diagnose BPPV, the ear-nose-throat (ENT) physician will complete a detailed patient questionnaire. If the ENT suspects BPPV, he/she will conduct a Dix-Hallpike test. This is a test designed to deliberately trigger the vertigo. The doctor will have the patient sit upright on a flat surface. With his head turned to the side, the patient will lie down quickly. A feeling of dizziness and nystagmus (shaking of the eye) accompany positional vertigo in such cases. If another type of vertigo or vestibular disorder is present, then there may be other causes, such as cardiovascular problems."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "what-is-a-nystagmus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-a-nystagmus",
    "aria-label": "what is a nystagmus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is a nystagmus?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A nystagmus is an uncontrolled, rhythmic movement of an organ. As a rule, it involves the eyes, which can also be checked with the Dix-Hallpike test. This nystagmus is a typical diagnostic indicator of the presence of positional vertigo, because it reflects a coordination failure between balance and sight."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "how-does-the-ent-physician-determine-which-canal-is-irritated",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-does-the-ent-physician-determine-which-canal-is-irritated",
    "aria-label": "how does the ent physician determine which canal is irritated permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How does the ENT physician determine which canal is irritated?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A crucial factor is the direction in which the eyes move with the nystagmus. If it is horizontal, the lateral canal is affected. However, the most common form of vertigo occurs when the ear stones are in the posterior canal, in which case, the nystagmus is vertical. In contrast to the lateral and posterior canals, the anterior canal is rarely affected."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-exercises-to-help-with-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-exercises-to-help-with-bppv",
    "aria-label": "treatment exercises to help with bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment: exercises to help with BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "BBPV cannot be treated with medication"), ", because, according to current knowledge, it would have no effect. Instead, positional exercises have been developed, which help the detached particles return to the otolith organs (utricle and saccule). There are different types, or forms, of positional vertigo. The most common are anterior (farther forward) and posterior (farther back) forms, which indicate either the anterior or posterior canals. The vertigo can usually be relieved with the appropriate exercises. Both types can be treated with the Sémont or the Epley maneuvers."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "sémont-maneuver",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#s%C3%A9mont-maneuver",
    "aria-label": "sémont maneuver permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sémont maneuver"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For this maneuver, the patient sits upright in front of the physician, head turned 45° to show the affected side to the doctor. Then the patient abruptly lies down on the affected side, head remaining in the same position. Now the patient is lying sideways on the examining table and looking upward. He maintains this position for 2-3 minutes, then turns over 180° onto his other side, keeping his head in the same position, so that now he’s looking in the direction of the table. Then the patient is slowly returned to his initial position and stays there for 3 minutes."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Short tutorial for the Sémont maneuver in three easy steps:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sitting upright"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Body tilts onto one shoulder"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rapid 180° turn onto the other shoulder"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/s%C3%A9mont-maneuver.jpg",
    alt: "Sémont Maneuver - Step by Step Guide",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Customize maneuver as needed for right or left ear.")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "epley-maneuver",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#epley-maneuver",
    "aria-label": "epley maneuver permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Epley maneuver"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This maneuver was created by Dr. John Epley in 1980. The patient sits upright, legs outstretched, in front of the physician. The head is turned 45° to the affected side, so the physician is looking at the good ear. Then the patient changes rapidly to a supine position so that the head hangs over the edge of the examination table. The patient must remain in this position until the vertigo and nystagmus have receded (at least 1 minute). Then the patient turns onto the healthy side, keeping his head in the same position, and waits approximately another minute. The last step in this maneuver is sitting upright again. The patient should keep his eyes closed to avoid any vertigo-induced nausea during this maneuver."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If the patient does not respond to this treatment, positional training, according to Brandt and Daroff, is recommended. During these exercises, the patient changes position alternately to the right and the left. He remains in one position for about 30 seconds, and he sits upright again in between the positions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Directions for the Epley maneuver in 5 steps:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sitting upright, head turned 45° to the right"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Lying down"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Head turned 90° to the left"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Whole body turned 90° to the left"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sitting up with head tipped slightly to the left"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/epley-maneuver.jpg",
    alt: "Epley Maneuver - Step by Step Guide",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Repeat the maneuver mirrored for the left ear.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "course-and-prognosis-of-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#course-and-prognosis-of-bppv",
    "aria-label": "course and prognosis of bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Course and prognosis of BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Positional vertigo is considered a mildly uncomfortable condition that is not dangerous in and of itself. Although it often resolves on its own without any applicable treatment, there are some exceptions. It has been known to follow a longer course, from a few days to over several weeks and months, even several years. This is the reason why a timely visit to an ENT doctor is strongly recommended. Even if the form of vertigo disappears on its own, there can be a risk of having to face the unpleasant symptoms for a longer period."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "is-there-such-a-thing-as-malignant-positional-vertigo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#is-there-such-a-thing-as-malignant-positional-vertigo",
    "aria-label": "is there such a thing as malignant positional vertigo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Is there such a thing as malignant positional vertigo?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Not directly. The term “benign positional vertigo” is used because it does not create any immediate harm in and of itself. Harmful results, such as falls or nervous stress, are only caused indirectly."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "origin-and-causes-of-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#origin-and-causes-of-bppv",
    "aria-label": "origin and causes of bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Origin and causes of BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Generally, benign positional vertigo is accompanied by detachment of the ear stones, which then arrive in the semicircular canals, thus travelling into the new deepest point. The canal liquid moves, leading to deviation in the cupula (gelatinous cone in the posterior canal) and irritating the affected canal. There are different causes of the ear stone detachment:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Inflammation of the balance organs in the inner ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Previous motor vehicle and sports accidents"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Head trauma or injury"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Advanced age"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the case of disturbances or balance organ failure, the possibility exists that spatial information is being transmitted to the brain incorrectly, typically resulting in rotary vertigo. The precise cause of otolith detachment is s till unknown."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "other-forms-of-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#other-forms-of-bppv",
    "aria-label": "other forms of bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Other forms of BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In addition to positional vertigo, there are other forms of vertigo which have other causes. ", React.createElement(_components.a, {
    href: "/hearing-loss/meniere-disease",
    className: "c-md-a"
  }, "Meniérè’s disease"), ", for instance, is caused by pressure in the balance and hearing organs, and is usually accompanied by sweating, nausea, and vomiting. It only affects one ear, which can also experience tinnitus and hearing loss. Severe mental stress can also trigger swaying, or rocking, vertigo. Any brain injury also raises the possibility of a pre-existing more central vertigo, which should be examined by a physician in any event."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "infant-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#infant-bppv",
    "aria-label": "infant bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Infant BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Children rarely experience positional vertigo"), ". Although childhood dizziness and balance problems are not uncommon, they are usually caused by migraine or other ailments. The shifting of the otoconia crystals does exist in children, but it does not necessarily create a feeling of dizziness. Modern medicine does not know precisely why the otoliths detach. It is assumed that the risk increases with advancing age, and thus the likelihood of benign positional vertigo occurring increases after age 35."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-history-of-bppv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-history-of-bppv",
    "aria-label": "the history of bppv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The history of BPPV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The first description of benign positional vertigo goes back to otologist Róbert Bárány in approximately 1920. In 1969, causation research by H.F. Schuknecht created new impetus, discovering residue of ear stones in the cupolas of deceased patients with positional vertigo. Four years later, Schuknecht and Ruby formulated the conclusive cupulolithiasis theory of the cause of positional vertigo. Nevertheless, the cause is still not fully understood, because full recovery cannot occur, even with ideal treatment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Cupulolithiasis theory & canalolithiasis hypothesis\nThe cupulolithiasis theory postulates that ear crystals are deposited in the cupula in the posterior canal. The otolith creates an imbalance of gravitational force in this point which leads to the sensation of spinning and other known symptoms."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the canalolithiasis hypothesis, the dizziness is caused more frequently by a prior trauma or degenerative effects. Small portions of the otoliths detach and create a type of clot in the free movement of the posterior canal, leading to an almost complete blockage of the lumen (interior of the hollow organs). Rapid head positioning can move the clot to the opposite side and out of the canal, leading to the typical symptoms."), "\n", React.createElement(LandingPageCta, {
    copy: "SIGN UP TO TRY HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
